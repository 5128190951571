import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.scss";

class More extends PureComponent {
	static propTypes = {
		className: PropTypes.string
	};

	static defaultProps = {
		className: undefined
	};

	render() {
		const {
			className
		} = this.props;

		return (
			<div className={ classnames(styles.more, className) }>
				<FontAwesomeIcon icon={ faCircle }/>
			</div>
		);
	}
}

export default More;
