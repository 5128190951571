/* eslint-disable react/no-unused-state */
import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./styles.module.scss";
import { getData } from "data";

export const NavigationContext = React.createContext({});

class Navigation extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		children: PropTypes.node.isRequired,
		location: PropTypes.shape({
			pathname: PropTypes.string
		}).isRequired
	};

	static defaultProps = {
		className: undefined
	};

	state = {
		active: {},
		setActive: (key, isActive) => {
			this.setState(({ active: prevActive }) => ({
				active: {
					...prevActive,
					[key]: isActive
				}
			}));
		},
		goToItem: (id, direct = false) => {
			document.getElementById(id)?.scrollIntoView({ behavior: direct ? "auto" : "smooth" });
		}
	}

	buildHandleSectionClick = (id) => () => {
		const {
			goToItem
		} = this.state;
		goToItem(id);
	}

	render() {
		const {
			className,
			children,
			location: {
				pathname
			}
		} = this.props;

		const {
			active
		} = this.state;

		const anyActive = Object.values(active).some((x) => x);

		const { sections } = getData(pathname);

		return (
			<>
				<NavigationContext.Provider value={ this.state }>
					{ children }
				</NavigationContext.Provider>
				<nav className={ classnames(styles.navigation, { [styles.active]: anyActive }, className) }>
					<div className={ styles.holder }>
						{
							sections.map(({ id, title }) => {
								if (!title) {
									return null;
								}

								return (
									<div
										key={ id }
										onClick={ this.buildHandleSectionClick(id) }
										className={ classnames({ [styles.active]: active[id] }) }
									>
										<span>
											{ title }
										</span>
									</div>
								);
							})
						}
					</div>
				</nav>
			</>
		);
	}
}

export default withRouter(Navigation);
