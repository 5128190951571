const isObject = (item) => item && typeof item === "object" && !Array.isArray(item);

export const mergeObjects = (target, source) => {
	const output = Object.assign({}, target);
	if (isObject(target) && isObject(source)) {
		Object.keys(source).forEach((key) => {
			if (isObject(source[key])) {
				if (!(key in target)) {
					Object.assign(output, { [key]: source[key] });
				} else {
					output[key] = mergeObjects(target[key], source[key]);
				}
			} else {
				Object.assign(output, { [key]: source[key] });
			}
		});
	}
	return output;
};

export const availableLanguages = ["en", "es"];

export const getLanguageFromPath = (lang) => lang.replace(/[^a-z]/gi, "");

export const getDefaultLanguage = () => {
	const browserLanguage = navigator?.language;
	if (browserLanguage) {
		for (const availableLanguage of availableLanguages) {
			if (browserLanguage.toLocaleLowerCase().includes(availableLanguage)) {
				return availableLanguage;
			}
		}
	}
	return availableLanguages[0];
};


export const isRunningOnLocalServer = () => {
	const host = window.location.hostname;
	return host === "localhost" || host === "localhost";
};

export const isPrerender = () => navigator.userAgent === "ReactSnap";
