import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCookieBite } from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.scss";

class Policy extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		data: PropTypes.shape({
			content: PropTypes.string,
			accept: PropTypes.string
		}).isRequired
	};

	static defaultProps = {
		className: undefined
	};

	state = {
		show: false
	};

	componentDidMount() {
		this.loadPolicyData();
	}

	loadPolicyData = () => {
		const policyAccepted = Boolean(localStorage.getItem("policyAccepted"));
		if (!policyAccepted) {
			this.setState({ show: true });
		}
	}

	handleAcceptPolicy = () => {
		localStorage.setItem("policyAccepted", true);
		this.setState({ show: false });
	}

	render() {
		const {
			className,
			data: {
				content,
				accept
			}
		} = this.props;

		const {
			show
		} = this.state;

		return show && (
			<div className={ classnames(styles.policy, className) }>
				<FontAwesomeIcon icon={ faCookieBite }/>
				<div dangerouslySetInnerHTML={ { __html: content } }/>
				<button onClick={ this.handleAcceptPolicy }>
					{ accept }
				</button>
			</div>
		);
	}
}

export default Policy;
