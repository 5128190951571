import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Cta from "components/cta";
import styles from "./styles.module.scss";

class Contact extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		data: PropTypes.shape({
			header: PropTypes.string,
			references: PropTypes.shape({
				intro: PropTypes.string,
				companies: PropTypes.arrayOf(PropTypes.shape({
					image: PropTypes.string,
					link: PropTypes.string
				}))
			}),
			cta: PropTypes.object
		}).isRequired
	};

	static defaultProps = {
		className: undefined
	};

	render() {
		const {
			className,
			data: {
				header,
				references: {
					intro,
					companies
				},
				cta
			}
		} = this.props;

		return (
			<div className={ classnames(styles.contact, className) }>
				<h2>
					{ header }
				</h2>
				<div className={ styles.references }>
					<div
						className={ styles.intro }
						dangerouslySetInnerHTML={ { __html: intro } }
					/>
					<div className={ styles.companies }>
						{
							companies.map(({ image, link }) => (
								<a
									key={ link }
									target="_blank"
									href={ link }
									rel="noreferrer"
								>
									<img
										src={ image }
										alt={ link }
									/>
								</a>
							))
						}
					</div>
				</div>
				<Cta
					className={ styles.cta }
					data={ cta }
				/>
			</div>
		);
	}
}

export default Contact;
