import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./styles.module.scss";

class Logo extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		width: PropTypes.number
	};

	static defaultProps = {
		className: undefined,
		width: 500
	};

	render() {
		const {
			className,
			width
		} = this.props;

		return (
			<svg
				className={ classnames(styles.logo, className) }
				width={ width }
				viewBox="0 0 538 174"
			>
				<path d="M470,20.1V173h69V157H488V20.1H470Z"/>
				<path d="M392,20.1H368l-54,153h19l47-139,49,139h19Z"/>
				<path d="M258,153h24L336,0H317L270,139,221,0H202Z"/>
				<path d="M160,20.1V173h18V20.1H160Z"/>
				<path d="M18,173H1V20H29l84,138V20h18V173H102L18,35V173Z"/>
			</svg>
		);
	}
}

export default Logo;
