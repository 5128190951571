import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./styles.module.scss";

class Construction extends PureComponent {
	static propTypes = {
		className: PropTypes.string
	};

	static defaultProps = {
		className: undefined
	};

	render() {
		const {
			className
		} = this.props;

		return (
			<div className={ classnames(styles.construction, className) }>
				Under construction
				<div className={ styles.indicator }/>
			</div>
		);
	}
}

export default Construction;
