import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./styles.module.scss";

class Packages extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		data: PropTypes.shape({
			header: PropTypes.string,
			description: PropTypes.string,
			packages: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string,
					title: PropTypes.string,
					description: PropTypes.string,
					tech: PropTypes.arrayOf(PropTypes.shape({
						label: PropTypes.string,
						link: PropTypes.string
					}))
				})
			)
		}).isRequired
	};

	static defaultProps = {
		className: undefined
	};

	render() {
		const {
			className,
			data: {
				header,
				description,
				packages
			}
		} = this.props;

		return (
			<div className={ classnames(styles.packages, className) }>
				<h2>
					{ header }
				</h2>
				<h3>
					{ description }
				</h3>
				<div className={ styles.holder }>
					{
						packages.map(({ id, title, description: content, tech }) => (
							<div
								key={ id }
								className={ styles.item }
							>
								<div className={ styles.title }>
									{ title }
								</div>
								<div className={ styles.data }>
									<div className={ styles.content }>
										<div className={ styles.description }>
											{ content }
										</div>
										<div className={ styles.tech }>
											{
												tech.map(({ label, link }) => (
													<a
														key={ label }
														href={ link }
														target="_blank"
														rel="noreferrer"
													>
														{ label }
													</a>
												))
											}
										</div>
									</div>
								</div>
							</div>
						))
					}
				</div>
			</div>
		);
	}
}

export default Packages;
