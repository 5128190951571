import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Transition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import styles from "./styles.module.scss";

class Cases extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		data: PropTypes.shape({
			header: PropTypes.string,
			cases: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string,
					title: PropTypes.string,
					image: PropTypes.string,
					description: PropTypes.string,
					more: PropTypes.string,
					content: PropTypes.string
				})
			)
		}).isRequired
	};

	static defaultProps = {
		className: undefined
	};

	state = {
		selectedCase: null,
		showDetails: false
	}

	buildShowCaseDetailsHandler = (currentCase) => () => {
		this.setState({
			selectedCase: currentCase,
			showDetails: true
		});
	}

	handleHideDetails = () => {
		this.setState({
			showDetails: false
		});
	}

	render() {
		const {
			className,
			data: {
				header,
				cases
			}
		} = this.props;

		const {
			selectedCase,
			showDetails
		} = this.state;

		return (
			<div className={ classnames(styles.cases, className) }>
				<h2>
					{ header }
				</h2>
				<div className={ styles.panel }>
					{
						cases.map((currentCase) => {
							const {
								id,
								title,
								image,
								description
							} = currentCase;

							return (
								<div
									key={ id }
									className={ styles.case }
								>
									<div className={ styles.title }>
										{ title }
									</div>
									<div className={ styles.spotlight }>
										<img
											src={ image }
											alt={ title }
										/>
									</div>
									<div className={ styles.description }>
										{ description }
									</div>
									<button onClick={ this.buildShowCaseDetailsHandler(currentCase) }>
										<FontAwesomeIcon icon={ faChevronRight }/>
									</button>
								</div>
							);
						})
					}
				</div>
				<Transition
					in={ showDetails }
					timeout={ 1000 }
				>
					{
						(state) => (
							<div className={ classnames(styles.details, styles[state]) }>
								<div className={ styles.frame }>
									<div className={ styles.title }>
										<button onClick={ this.handleHideDetails }>
											<FontAwesomeIcon icon={ faChevronLeft }/>
										</button>
										{ selectedCase && selectedCase.title }
									</div>
									<div className={ styles.content }>
										{ selectedCase && selectedCase.content }
									</div>
								</div>
							</div>
						)
					}
				</Transition>
			</div>
		);
	}
}

export default Cases;
