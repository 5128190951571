import React, { PureComponent } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
	Switch,
	Route,
	Redirect,
	withRouter
} from "react-router-dom";
import Home from "./home";
import Navigation from "components/navigation";
import Footer from "components/footer";
import { availableLanguages, getDefaultLanguage, isPrerender } from "tools";
import styles from "./styles.module.scss";

class App extends PureComponent {
	static propTypes = {
		location: PropTypes.object.isRequired
	};

	state = {
		active: false
	}

	componentDidMount() {
		if (!isPrerender()) {
			setTimeout(this.activate, 200);
		}
	}

	activate = () => {
		this.setState({	active: true });
	}

	renderRoutes = () => availableLanguages.map((availableLanguage) => (
		<Route
			key={ availableLanguage }
			path={ `/${ availableLanguage }` }
		>
			<Navigation>
				<Home/>
				<Footer/>
			</Navigation>
		</Route>
	))

	render() {
		const {
			location: {
				pathname
			}
		} = this.props;

		const {
			active
		} = this.state;

		return (
			<div className={ classNames(styles.app, { [styles.active]: active }) }>
				<Switch>
					{
						!isPrerender() && (
							<Redirect
								from="/:url*(/+)"
								to={ pathname.slice(0, -1) }
							/>
						)
					}
					{ this.renderRoutes() }
					<Redirect to={ `/${ getDefaultLanguage() }` }/>
				</Switch>
			</div>
		);
	}
}

export default withRouter(App);
