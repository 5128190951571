import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./styles.module.scss";

class Cta extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		data: PropTypes.shape({
			content: PropTypes.string,
			button: PropTypes.string,
			link: PropTypes.string
		}).isRequired
	};

	static defaultProps = {
		className: undefined
	};

	render() {
		const {
			className,
			data: {
				content,
				button,
				link
			}
		} = this.props;

		return (
			<div className={ classnames(styles.cta, className) }>
				<div className={ styles.content }>
					{ content }
				</div>
				<a
					className={ styles.btn }
					target="_blank"
					href={ link }
					rel="noreferrer"
				>
					{ button }
				</a>
			</div>
		);
	}
}

export default Cta;
