import React, { PureComponent } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import { NavigationContext } from "components/navigation";
import { getLanguageFromPath, availableLanguages } from "tools";
import { getData } from "data";
import styles from "./styles.module.scss";

class Footer extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		location: PropTypes.shape({
			pathname: PropTypes.string
		}).isRequired
	};

	static defaultProps = {
		className: undefined
	};

	static contextType = NavigationContext;

	render() {
		const {
			className,
			location: {
				pathname
			}
		} = this.props;

		const {
			active
		} = this.context;

		const anyActive = Object.values(active).some((x) => x);

		const { contact } = getData(pathname);

		return (
			<footer className={ classnames(styles.footer, { [styles.active]: anyActive }, className) }>
				{
					availableLanguages.map((availableLanguage) => {
						if (getLanguageFromPath(pathname) === availableLanguage) {
							return null;
						}

						return (
							<Link
								key={ availableLanguage }
								to={ `/${ availableLanguage }` }
							>
								{ availableLanguage.toLocaleUpperCase() }
							</Link>
						);
					})
				}
				{
					contact.map(({ link, content }) => (
						<a
							href={ link }
							key={ content }
							target="_blank"
							rel="noreferrer"
						>
							{ content }
						</a>
					))
				}
			</footer>
		);
	}
}

export default withRouter(Footer);
