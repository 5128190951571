import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Waypoint } from "react-waypoint";
import More from "components/more";
import Banner from "./banner";
import Services from "./services";
import Cases from "./cases";
import Contact from "./contact";
import Packages from "./packages";
import Intro from "./intro";
import Construction from "./construction";
import { NavigationContext } from "components/navigation";
import { isPrerender } from "tools";
import styles from "./styles.module.scss";

const Sections = {
	"intro": Intro,
	"banner": Banner,
	"services": Services,
	"cases": Cases,
	"packages": Packages,
	"contact": Contact
};

class Section extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		section: PropTypes.shape({
			id: PropTypes.string,
			type: PropTypes.string,
			data: PropTypes.object,
			more: PropTypes.bool,
			background: PropTypes.shape({
				config: PropTypes.object
			}),
			tip: PropTypes.object
		}).isRequired
	};

	static defaultProps = {
		className: undefined
	};

	state = {
		introDisplayed: true
	}

	componentDidMount() {
		this.handleLoad();
	}

	static contextType = NavigationContext;

	handleLoad = () => {
		const {
			section: {
				id
			}
		} = this.props;

		if (id === "intro") {
			const introDisplayed = Boolean(sessionStorage.getItem("introDisplayed"));
			this.setState({
				introDisplayed
			}, () => {
				if (!introDisplayed) {
					const {
						goToItem
					} = this.context;
					setTimeout(() => {
						goToItem("intro", true);
					}, 100);
					setTimeout(() => {
						goToItem("banner");
						setTimeout(() => {
							sessionStorage.setItem("introDisplayed", true);
							this.setState({
								introDisplayed: true
							});
						}, 1000);
					}, 7500);
				}
			});
		}
	}

	buildHandleVisibilityChange = (isVisible) => () => {
		const {
			section: {
				id
			}
		} = this.props;

		if (id !== "intro") {
			const {
				setActive
			} = this.context;

			setActive(id, isVisible);
		}
	}

	renderSectionContent = () => {
		const {
			section: {
				type,
				data
			}
		} = this.props;

		const SectionTag = Sections[type];

		if (!SectionTag) {
			return (
				<Construction/>
			);
		}

		return (
			<SectionTag data={ data }/>
		);
	}

	render() {
		const {
			className,
			section: {
				id,
				more
			}
		} = this.props;

		const {
			introDisplayed
		} = this.state;

		const isIntro = id === "intro";
		const showIntro = !introDisplayed && !isPrerender();

		return (!isIntro || showIntro) && (
			<Waypoint
				onEnter={ this.buildHandleVisibilityChange(true) }
				onLeave={ this.buildHandleVisibilityChange(false) }
				fireOnRapidScroll={ true }
				topOffset="40%"
				bottomOffset="40%"
			>
				<section
					id={ id }
					className={ classnames(styles.section, className) }
				>
					<div className={ styles.content }>
						{ this.renderSectionContent() }
					</div>
					{
						more && (
							<More className={ styles.more }/>
						)
					}
				</section>
			</Waypoint>
		);
	}
}

export default Section;
