import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./styles.module.scss";

class Copy extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		content: PropTypes.string.isRequired
	};

	static defaultProps = {
		className: undefined
	};

	render() {
		const {
			className,
			content
		} = this.props;

		return (
			<div
				className={ classnames(styles.copy, className) }
				dangerouslySetInnerHTML={ {	__html: content } }
			/>
		);
	}
}

export default Copy;
