import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Logo from "components/logo";
import styles from "./styles.module.scss";

class Banner extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		data: PropTypes.shape({
			slogan: PropTypes.string,
			description: PropTypes.string
		}).isRequired
	};

	static defaultProps = {
		className: undefined
	};

	render() {
		const {
			className,
			data: {
				slogan,
				description
			}
		} = this.props;

		return (
			<header className={ classnames(styles.banner, className) }>
				<Logo
					className={ styles.logo }
					width={ 246 }
				/>
				<h3 className={ styles.slogan }>
					{ slogan }
				</h3>
				<h1 className={ styles.description }>
					{ description }
				</h1>
			</header>
		);
	}
}

export default Banner;
