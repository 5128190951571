import en from "./content.en.json";
import es from "./content.es.json";
import { getLanguageFromPath, availableLanguages } from "tools";

const data = {
	en,
	es
};


export const getData = (lang) => data[getLanguageFromPath(lang)] || data[availableLanguages[0]];

export const settings = {
	gaCode: "G-4DWF04CCNP"
};
