import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./styles.module.scss";

class Services extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		data: PropTypes.shape({
			header: PropTypes.string,
			services: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string,
					title: PropTypes.string,
					description: PropTypes.string
				})
			)
		}).isRequired
	};

	static defaultProps = {
		className: undefined
	};

	render() {
		const {
			className,
			data: {
				header,
				services
			}
		} = this.props;

		return (
			<div className={ classnames(styles.services, className) }>
				<h2>
					{ header }
				</h2>
				<div className={ styles.panel }>
					{
						services.map(({ id, title, description }) => (
							<div
								key={ id }
								className={ styles.service }
							>
								<div className={ styles.title }>
									{ title }
								</div>
								<div className={ styles.description }>
									{ description }
								</div>
							</div>
						))
					}
				</div>
			</div>
		);
	}
}

export default Services;
