import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./styles.module.scss";
import Section from "components/section";
import Copy from "components/copy";
import Policy from "components/policy";
import { getData } from "data";
import { isPrerender } from "tools";

class Home extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		location: PropTypes.shape({
			pathname: PropTypes.string
		}).isRequired
	};

	static defaultProps = {
		className: undefined
	};

	componentDidMount() {
		const {
			location: {
				pathname
			}
		} = this.props;

		const {
			description,
			keywords
		} = getData(pathname);

		document.querySelector("meta[name='description']").setAttribute("content", description);
		document.querySelector("meta[name='keywords']").setAttribute("content", keywords);
	}

	render() {
		const {
			className,
			location: {
				pathname
			}
		} = this.props;

		const {
			copy,
			policy,
			sections
		} = getData(pathname);

		return (
			<div className={ classnames(styles.home, className) }>
				{
					sections.map((x) => (
						<Section
							key={ x.id }
							section={ x }
						/>
					))
				}
				<Copy content={ copy }/>
				{
					!isPrerender() && (
						<Policy data={ policy }/>
					)
				}
			</div>
		);
	}
}

export default withRouter(Home);
