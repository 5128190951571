import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as d3 from "d3";
import styles from "./styles.module.scss";

const setup = () => {
	const originalWidth = 1920;
	const originalHeight = 550;

	const currentWindowWidth = window.innerWidth;
	const h = Math.max(parseInt(currentWindowWidth / originalWidth * originalHeight), 200);
	const w = Math.max(currentWindowWidth, 800);
	const amountOfNodes = 500;
	const leftGroup = 370;
	const nodes = d3.range(amountOfNodes).map(Object);

	const vis = d3
		.select(`.${ styles.intro }`)
		.append("svg:svg")
		.attr("width", "100%")
		.attr("height", "100%")
		.attr("viewBox", `0 0 ${ w } ${ h }`);

	const force = d3.layout.force().nodes(nodes).links([]).friction(0.86).size([w, h]).start();

	const node = vis
		.selectAll("circle.node")
		.data(nodes)
		.enter()
		.append("svg:circle")
		.attr("class", "node")
		.attr("cx", ({ x }) => x)
		.attr("cy", ({ y }) => y)
		.attr("r", () => 4)
		.style("fill", (_, i) => i > leftGroup ? "#CCC" : "#A12935")
		.style("stroke-width", 0)
		.call(force.drag);

	vis
		.style("opacity", 1e-6)
		.transition()
		.duration(1400)
		.style("opacity", 1);

	force.on("tick", (e) => {
		const k = 7 * e.alpha;
		nodes.forEach((o, i) => {
			o.x += i > leftGroup ? k : -k;
		});

		node.attr("cx", ({ x }) => x).attr("cy", ({ y }) => y);
	});
};

class Intro extends PureComponent {
	static propTypes = {
		className: PropTypes.string,
		data: PropTypes.shape({
			main: PropTypes.string,
			extra: PropTypes.string
		}).isRequired
	};

	static defaultProps = {
		className: undefined
	};

	state = {
		started: false
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				started: true
			}, setup);
		}, 400);
	}

	render() {
		const {
			className,
			data: {
				main,
				extra
			}
		} = this.props;

		const {
			started
		} = this.state;

		return (
			<div
				className={ classnames(styles.intro, { [styles.started]: started }, className) }
			>
				<div className={ styles.holder }>
					<div
						className={ styles.main }
						dangerouslySetInnerHTML={ {	__html: main } }
					/>
					<div
						className={ styles.extra }
						dangerouslySetInnerHTML={ {	__html: extra } }
					/>
				</div>
			</div>
		);
	}
}

export default Intro;
